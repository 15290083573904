import Vue from 'vue'
import VueRouter from 'vue-router'

import Recharge from '../views/Recharge.vue'


Vue.use(VueRouter)

const routes = [
  //充值页
  {
    path: '/',
    name: '',
    component: () => import('../views/PersonalCenter.vue')
  },
  {
    path: '/Recharge',
    name: 'Recharge',
    component: Recharge
  },
  //支付详情
  {
    path: '/PayDetails',
    name: 'PayDetails',
    component: () => import('../views/PayDetails.vue')
  },
  //个人中心
  {
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: () => import('../views/PersonalCenter.vue')
  },
  //我的设备
  {
    path: '/MyDevice',
    name: 'MyDevice',
    component: () => import('../views/MyDevice.vue')
  },
  //常见问题
  {
    path: '/MyQuestion',
    name: 'MyQuestion',
    component: () => import('../views/MyQuestion.vue')
  },
   //我的信息
   {
    path: '/MyInfo',
    name: 'MyInfo',
    component: () => import('../views/MyInfo.vue')
  },
  
   //我的物流
   {
    path: '/MyLogistics',
    name: 'MyLogistics',
    component: () => import('../views/MyLogistics.vue')
  },
   //我的地址
   {
    path: '/MyAddress',
    name: 'MyAddress',
    component: () => import('../views/MyAddress.vue')
  },
   //编辑添加地址 
   {
    path: '/EditAddress',
    name: 'EditAddress',
    component: () => import('../views/EditAddress.vue')
  },
  //我的订单
  {
    path: '/MyOrder',
    name: 'MyOrder',
    component: () => import('../views/MyOrder.vue')
  },

  //意见反馈
  {
    path: '/Feedback',
    name: 'Feedback',
    component: () => import('../views/Feedback.vue')
  },

  //设备绑定
  {
    path: '/Bind',
    name: 'Bind',
    component: () => import('../views/Bind.vue')
  },

  //设备绑定
  {
    path: '/BindCamera',
    name: 'BindCamera',
    component: () => import('../views/BindCamera.vue')
  },

  //设备详情
  {
    path: '/DeviceDetails',
    name: 'DeviceDetails',
    component: () => import('../views/DeviceDetails.vue')
  },
  //支付状态
  {
    path: '/PayStatus',
    name: 'PayStatus',
    component: () => import('../views/PayStatus.vue')
  },
  //设备查询
  {
    path: '/QueryDevice',
    name: 'QueryDevice',
    component: () => import('../views/QueryDevice.vue')
  },

  //实名认证
  {
    path: '/RealNameAuthentication',
    name: 'RealNameAuthentication',
    component: () => import('../views/RealNameAuthentication.vue')
  },

  //实名认证
  {
    path: '/Authentication',
    name: 'Authentication',
    component: () => import('../views/Authentication.vue')
  },

  //联通小程序中转页
  {
    path: '/ZglxWeappTransfer',
    name: 'ZglxWeappTransfer',
    component: () => import('../views/ZglxWeappTransfer.vue')
  },

   //客服
   {
    path: '/CustomerService',
    name: 'CustomerService',
    component: () => import('../views/CustomerService.vue')
  },

   //设备扫码关注公众号
   {
    path: '/Qrcode',
    name: 'Qrcode',
    component: () => import('../views/Qrcode.vue')
  },

  //教程
  {
    path: '/Tutorial',
    name: 'Tutorial',
    component: () => import('../views/Tutorial.vue')
  },




  //H5 APP

  //流量充值
  {
    path: '/apph5Recharge',
    name: 'apph5Recharge',
    component:  () => import('../views/appH5/Recharge.vue')
  },
  //支付详情
  {
    path: '/apph5PayDetails',
    name: 'apph5PayDetails',
    component: () => import('../views/appH5/PayDetails.vue')
  },
   //支付状态
   {
    path: '/apph5PayStatus',
    name: 'apph5PayStatus',
    component: () => import('../views/appH5/PayStatus.vue')
  },

  //摄像头

  //流量充值
  {
    path: '/cameraRecharge',
    name: 'cameraRecharge',
    component:  () => import('../views/camera/Recharge.vue')
  },
  //支付详情
  {
    path: '/cameraPayDetails',
    name: 'cameraPayDetails',
    component: () => import('../views/camera/PayDetails.vue')
  },
   //支付状态
   {
    path: '/cameraPayStatus',
    name: 'cameraPayStatus',
    component: () => import('../views/camera/PayStatus.vue')
  },

  //摄像机详情
  {
    path: '/cameraDetails',
    name: 'CameraDetails',
    component: () => import('../views/camera/DeviceDetails.vue')
  },
  //摄像机列表
  {
    path: '/myCameraDevice',
    name: 'MyCameraDevice',
    component: () => import('../views/camera/MyDevice.vue')
  },
  //摄像机客服
  {
    path: '/CamerCustomerService',
    name: 'CamerCustomerService',
    component: () => import('../views/camera/CustomerService.vue')
  },


  // new
  
  // 添亿
  {
    path: '/newIndex',
    name: 'newIndex',
    component:  () => import('../views/new/index.vue')
  },
  // 新浦
  {
    path: '/xinpu',
    name: 'xinpu',
    component:  () => import('../views/new/xinpu/index.vue')
  },
  // 新浦
  {
    path: '/xunke',
    name: 'xunke',
    component:  () => import('../views/new/xunke/index.vue')
  },
  
  // 新商户主页
  {
    path: '/newChange',
    name: 'newChange',
    component:  () => import('../views/new/change.vue')
  },
  
  // 余额
  {
    path: '/newBalance',
    name: 'newBalance',
    component:  () => import('../views/new/balance.vue')
  },
  
  // 余额
  {
    path: '/newBalanceXinpu',
    name: 'newBalanceXinpu',
    component:  () => import('../views/new/xinpu/balance.vue')
  },
  
  // 余额
  {
    path: '/newBalanceXunke',
    name: 'newBalanceXunke',
    component:  () => import('../views/new/xunke/balance.vue')
  },
  
  // 余额管理
  {
    path: '/balanceManager',
    name: 'balanceManager',
    component:  () => import('../views/new/balanceManager.vue')
  },
  
  // 余额管理
  {
    path: '/balanceManagerXunke',
    name: 'balanceManagerXunke',
    component:  () => import('../views/new/xunke/balanceManager.vue')
  },

  // 阿里支付创建二维码
  {
    path: '/alipayPreCreate',
    name: 'alipayPreCreate',
    component:  () => import('../views/appH5/CreateQrcode.vue')
  },

  // 阿里支付创建二维码
  {
    path: '/alipayQrCode',
    name: 'alipayQrCode',
    component:  () => import('../views/appH5/AlipayQrCode.vue')
  },
  
  // 网络切换
  {
    path: '/netChange',
    name: 'netChange',
    component:  () => import('../views/new/netChange.vue')
  },

  
  // 客服活码
  {
    path: '/qrcodeKf',
    name: 'qrcodeKf',
    component:  () => import('../views/new/qrcode_kf.vue')
  },
  {
    path: '/xinpuQrcodeKf',
    name: 'xinpuQrcodeKf',
    component:  () => import('../views/new/xinpu/qrcode_kf.vue')
  },
]



const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base:  process.env.BASE_URL,
  routes
})

export default router
