import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//vant ui相关依赖
import Vant from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible'
import '@/utils/rem'
//全局CSS
import '@/assets/css/reset.css'
import sessionStorage from '@/utils/sessionStorage'
import localStorage from '@/utils/localStorage'
import publicFun from '@/utils/public'
import request from '@/request/api'
//页面标签
import Meta from "vue-meta";
import { Toast } from 'vant';
import { Dialog } from 'vant';

//微信SDK
import wx from "weixin-js-sdk";
import '@vant/touch-emulator';
Vue.use(Vant);
Vue.use(sessionStorage)
Vue.use(localStorage)
Vue.config.productionTip = false
Vue.config.ignoredElements.push('wx-open-launch-weapp')
//动态标题
Vue.use(Meta);
Vue.prototype.toast = Toast;
Vue.prototype.dialog = Dialog;
Vue.prototype.wx = wx;

Vue.prototype.$request = request
Vue.prototype.$publicFun = publicFun

console.log(process.env.NODE_ENV)
// import VConsole from 'vconsole'
// new VConsole()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
