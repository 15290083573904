import request from '@/request/api'
import Vue from 'vue'
import { Dialog } from 'vant';
import router from '@/router/index'
let publicFun = {}

publicFun.appId = 'wx194fb1d9900b5f10'; 
// publicFun.appId = 'wx9d322c920ac84911';
// publicFun.authUrl = "http://iotapp.iot.189.cn:9090/uapp/certifhtml/certif_entry.html?userCode=r/s9Tc00hjiKcR13MIjZHg==&passWord=9u8VuY1xbez6r6k/BBnLlw==&tmstemp=1585809315389"

//判断是不是微信浏览器。支付时判断
publicFun.isWeiXin = function() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
},


publicFun.getUrlappId = function() {
  let url=location.search
  let theRequest =new Object()
  if (url.indexOf("?") !== -1) {
      let str = url.substr(1);
      let strs = str.split("&");
      for(let i = 0; i < strs.length; i ++) {
          theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
      }
      var appId = theRequest.appId || theRequest.appid
      return appId
  }
}

//根据url上的地址获取缓存中对应的userinfo
publicFun.getUserInfo = function() {
  let url=location.search
  let theRequest =new Object()
  if (url.indexOf("?") !== -1) {
      let str = url.substr(1);
      let strs = str.split("&");
      for(let i = 0; i < strs.length; i ++) {
          theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
      }
      let appId = theRequest.appId || theRequest.appid
      if(appId){
        let userInfoJson = JSON.parse(localStorage.getItem("userInfoJson"))
        console.log(userInfoJson);
        
        if(userInfoJson[appId]){
          return userInfoJson[appId]
        }else{
          let obj = {}
          return obj
        }
      }
      return null
  }
  return null
}


//获取url地址后面的
publicFun.getRequest = function() {
    let url=location.search
    let theRequest =new Object()
    if (url.indexOf("?") !== -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for(let i = 0; i < strs.length; i ++) {
            theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
        }
        return theRequest
    }
}


//静默授权登录
//授权登录
publicFun.getTicket = function(callback){
  //如果有用户信息userInfoJson不授权登录
  let objData = publicFun.getRequest()
  if (objData !==undefined &&  (Object.prototype.hasOwnProperty.call(objData,'appId') || Object.prototype.hasOwnProperty.call(objData,'appid'))){
    let userInfoJson = JSON.parse(localStorage.getItem("userInfoJson"))
    let appId = objData.appId || objData.appid
    //有信息
    if(userInfoJson !== null && Object.prototype.hasOwnProperty.call(userInfoJson,[appId])  && userInfoJson[appId].id >= 63){
      callback(userInfoJson[appId])
    }else{

      //没有信息去获取用户信息  
      //如果授权了直接拿code去换取
      if (objData && objData.code){
        //获取用户信息
        request.getUserInfo(appId,{
          code:objData.code
        }).then(res=>{
          if(localStorage.getItem("userInfoJson")){
            let userInfoJson = JSON.parse(localStorage.getItem("userInfoJson"))
            //有信息
            if(userInfoJson[appId]){
              userInfoJson[appId] = res.data
              localStorage.setItem("userInfoJson", JSON.stringify(userInfoJson));
            }else{
              userInfoJson[appId] = res.data
              localStorage.setItem("userInfoJson", JSON.stringify(userInfoJson));
            }
          }else{
            let json = {
              [appId]:res.data
            }
            localStorage.setItem("userInfoJson", JSON.stringify(json));
          }
          callback(res.data)
        })
    
      }else{
        //授权
        request.getUserToken(appId ? appId : 'null',{
            "returnurl":encodeURIComponent(window.location.href)
        }).then(res=>{
            console.log('res',res)
            //跳转微信授权页面
            window.location.href = res.data.returnurl
        })
      }
    }
  }
}


//验证是否有设备，没有设备跳绑定页面
publicFun.getEquipmentList = function(){
  let userId = publicFun.getUserInfo().id
  request.getEquipmentList(userId).then(res=>{
    if(res.data.length == 0){
      Dialog.alert({
        title: '提示',
        message: '您还暂未绑定设备,去绑定设备',
      }).then(() => {
        router.push({name:'Bind',query:{appId:publicFun.getUrlappId()}})
      });
    }
  })
}



//调用jsApi
publicFun.getJSApi = function(){
  // Vue

  //安卓  只有扫码界面调用

  //ios 保存第一次的url

  let appId = publicFun.getUrlappId()
  var ua = navigator.userAgent.toLowerCase();
  let url = ""
  // ios 采用进入网页的第一个网址  android当前网址
  if (/iphone|ipad|ipod/.test(ua)) {
     url = localStorage.getItem("WxCodeUrl")
     if(!url)url = location.href
  } else {
    url = location.href.split('#')[0]
  }
  request.getJSApi(appId,{
    url:url
  }).then(res=>{
    console.log(res)
    Vue.prototype.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: res.data.appId, // 必填，公众号的唯一标识
      timestamp: res.data.timestamp , // 必填，生成签名的时间戳
      nonceStr:  res.data.nonceStr, // 必填，生成签名的随机串
      signature:  res.data.signature,// 必填，签名
      jsApiList: [  //必填，需要使用的JS接口列表
      'scanQRCode',
      'uploadImage',
      'chooseImage',
      'previewImage',
      ],
      openTagList: ['wx-open-launch-weapp']
    })
    Vue.prototype.wx.error(function(res){
      console.log(res)
      // alert("出错了：" + res.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
    });
    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
    Vue.prototype.wx.ready(() => {
      console.log(Vue.prototype.wx)
      console.log("ready")
    });
  })
}


publicFun.timeTransform = function(timestamp){
    let date = new Date(parseInt(timestamp));
    let Year = date.getFullYear();
    let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    // let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    // let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    // let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    // let  GMT =  Year + '-' + Moth + '-' + Day + '   '+ Hour +':'+ Minute  + ':' + Sechond;
    let  GMT =  Year + '-' + Moth + '-' + Day 
    return GMT
}


publicFun.getTime = function(){
  let date = new Date();
  let Year = date.getFullYear();
  let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
  let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
  let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  let  GMT =  Year + '-' + Moth + '-' + Day + ' '+ Hour +':'+ Minute  + ':' + Sechond;
  return GMT
}



export default publicFun



