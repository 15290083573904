import axios from '@/request/axios'

export default {
    //授权
    getUserToken (appid, params) {
        return new Promise((resolve, reject) => {
            axios.get('/authorize/' + appid, { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //根据code获取用户信息
    getUserInfo (appid, params) {
        return new Promise((resolve, reject) => {
            axios.get('/gt-info/' + appid, { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //个人中心
    getUser (userId) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/customer/' + userId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //设备详情
    getEquipmentDetails (id) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/' + id).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //绑定设备
    bindEquipment (params) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/bind', { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //设备列表查询
    getEquipmentList (userId) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/list/' + userId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //查询单设备
    getEquipmentInfo (params) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/info/by/sn', { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 查询充值订单列表
    getOrderList (data) {
        return new Promise((resolve, reject) => {
            axios.post('/weixin/pac/ord/list', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 查询充值订单通知列表
    getOrderSwipeList () {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/pac/ord/list/swipe').then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //获取扫一扫等第微信接口
    getJSApi (appId, params) {
        return new Promise((resolve, reject) => {
            axios.get('/get-jsapi-sign/' + appId, { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //获取短信验证码
    getSmsCode (phoneNum) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/send/code/' + phoneNum).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //获取地址列表
    getAddressList (params) {
        return new Promise((resolve, reject) => {
            axios.post('/weixin/address/list', params).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //添加地址
    addressAdd (params) {
        return new Promise((resolve, reject) => {
            axios.post('/weixin/address/add', params).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //删除
    addressDel (params) {
        return new Promise((resolve, reject) => {
            axios.post('/weixin/address/del', params).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //编辑地址
    addressEdit (params) {
        return new Promise((resolve, reject) => {
            axios.post('/weixin/address/edit', params).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //修改WiFi
    updateWifi (params) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/update/wifi', { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //关闭自动续费
    closeAutoPay (params) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/update/closeAutoPay', { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    // //获取套餐列表
    // getRechargeList(params){
    //     return new Promise((resolve, reject) => {
    //         axios.post('/weixin/card/pac/list',params).then(response => {
    //         resolve(response)
    //         }).catch(err => {
    //         reject(err)
    //         })
    //     })
    // },

    //获取套餐详情
    getRechargeDetails (id) {
        return new Promise((resolve, reject) => {
            axios.get('/card/pac/detail/' + id).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //保存用户埋点事件
    saveUsertrack (data) {
        return new Promise((resolve, reject) => {
            axios.post('/user/track/log/save', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },



    //投诉反馈
    complaintAdd (data) {
        return new Promise((resolve, reject) => {
            axios.post('/weixin/complaint/add', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //图片上传
    uploadImg (data) {
        return new Promise((resolve, reject) => {
            axios.post('/common/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },





    //查询商户公众号图片
    getQrcode (sn) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/getMerchantImg/' + sn).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //获取电信实名认证H5地址
    getDXAuthRealH5 (params) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/getDXAuthRealH5', { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //新增实名记录
    saveRealRecord (params) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/saveRealRecord', { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },




    //获取app内嵌H5套餐列表 指定商户号
    getPacketListMchId (mchId) {
        return new Promise((resolve, reject) => {
            axios.get('/card/pac/listCardPacByMchId/' + mchId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //获取app内嵌H5套餐列表 指定商户号和模式
    getPacketListMchIdAndMode (mchId, mode) {
        return new Promise((resolve, reject) => {
            axios.get('/card/pac/listCardPacByMchId/' + mchId + '?mode=' + mode).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //公众号H5获取套餐列表 根据SN号获取套餐
    getRechargeList (sn) {
        return new Promise((resolve, reject) => {
            axios.get('/card/pac/listCardPacBySn/' + sn).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //jsapi微信支付
    jsapiPay (data) {
        return new Promise((resolve, reject) => {
            axios.post('/pay/wx/jsapi/pay', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //微信支付H5
    wechatH5Pay (data) {
        return new Promise((resolve, reject) => {
            axios.post('/pay/wx/h5/pay', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //ali支付宝支付H5
    aliH5Pay (params) {
        return new Promise((resolve, reject) => {
            axios.get('/pay/alipay', { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //查询支付结果
    getpayStatus (ordNo) {
        return new Promise((resolve, reject) => {
            axios.get('/pay/weixinPayResult/' + ordNo).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //购买套餐appH5  0元免支付
    buyPacketappH5 (data) {
        return new Promise((resolve, reject) => {
            axios.post('/card/pac/h5/pay', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //购买套餐JsApi  0元免支付
    buyPacketJsApi (data) {
        return new Promise((resolve, reject) => {
            axios.post('/card/pac/jsapi/pay', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },





    //通联H5APP支付
    h5Pay (data) {
        return new Promise((resolve, reject) => {
            axios.post('/pay/tl/h5/pay', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },



    //获取支付方式
    getPayType () {
        return new Promise((resolve, reject) => {
            axios.get('/pay/getPayType').then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    //通联小程序支付
    appPay (data) {
        return new Promise((resolve, reject) => {
            axios.post('/pay/tl/app/pay', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //获取通联小程序跳转链接

    jumpToMiniApp (data) {
        return new Promise((resolve, reject) => {
            axios.post('/pay/tl/app/generatedJumpToMpUrl', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //通联支付结果
    gettlPayResult (ordNo) {
        return new Promise((resolve, reject) => {
            axios.get('/pay/tlPayResult/' + ordNo).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },




    //查询设备套餐列表 本腾套餐
    getRechargeListBT (type) {
        return new Promise((resolve, reject) => {
            axios.get('/card/pac/listDevicePacket?type=' + type).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //查询设备套餐列表 本腾套餐
    getRechargeListBTSN (sn, type) {
        return new Promise((resolve, reject) => {
            axios.get('/card/pac/listDevicePacket/btsn?sn=' + sn + '&type=' + type).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //查询设备自定义套餐列表
    getRechargeCustomList () {
        return new Promise((resolve, reject) => {
            axios.get('/card/pac/listDevicePacketCustom').then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 查询极速会员
    getSpeedVip (mchId) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/speedVip/' + mchId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    checkRealStatus (sn) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/checkRealStatus/' + sn).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //根据设备获取用户信息
    customerBySn (sn) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/customer/' + sn).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //获取默认设备信息
    myEquipment (userId) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/myEquipment/' + userId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //设置为默认设备
    settingDefault (userId, sn) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/settingDefault/' + userId + "/" + sn).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },



    //同步实名
    syncRealStatus (sn) {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/equipment/syncRealStatus/' + sn).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },



    //查询常见问题
    getQuestionList () {
        return new Promise((resolve, reject) => {
            axios.get('/weixin/question/list').then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 摄像头绑定
    cameraBind (data) {
        return new Promise((resolve, reject) => {
            axios.post('/camera/bind', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 摄像头列表
    getCameraList (userId) {
        return new Promise((resolve, reject) => {
            axios.get('/camera/list/' + userId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 我的摄像头
    getMyCamera (userId) {
        return new Promise((resolve, reject) => {
            axios.get('/camera/' + userId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // iccid查询摄像头
    getMyIccidCamera (iccid) {
        return new Promise((resolve, reject) => {
            axios.get('/camera/iccid/' + iccid).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 是否已绑定摄像头
    isBindCamera (userId) {
        return new Promise((resolve, reject) => {
            axios.get('/camera/isBind/' + userId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    // newH5

    // 获取认证token
    getCtccAuthToken (iccid, phone) {
        return new Promise((resolve, reject) => {
            axios.get('/newH5/auth/token?iccid=' + iccid + '&phone=' + phone).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 获取设备信息
    getCardInfoBySN (sn) {
        return new Promise((resolve, reject) => {
            axios.get('/newH5/cardInfo?sn=' + sn).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //微信支付H5
    getBalanceCard (businessId) {
        return new Promise((resolve, reject) => {
            axios.get('/newH5/balance/card?businessId=' + businessId).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //微信支付H5
    wechatNewH5Pay (data) {
        return new Promise((resolve, reject) => {
            axios.post('/newH5/wxpay', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //ali支付宝支付H5
    aliNewH5Pay (params) {
        return new Promise((resolve, reject) => {
            axios.get('/newH5/alipay', { params }).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    //ali支付宝支付H5
    balancePay (data) {
        return new Promise((resolve, reject) => {
            axios.post('/newH5/balancePay', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 设置已引导关注公众号
    updateDeviceGzhShow (data) {
        return new Promise((resolve, reject) => {
            axios.post('/newH5/updateDeviceGzhShow', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 设置设备网络
    updateDeviceNet (data) {
        return new Promise((resolve, reject) => {
            axios.post('/newH5/updateDeviceNet', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 根据参数键名查询参数值
    getConfigKey (configKey) {
        return new Promise((resolve, reject) => {
            axios.get('/config/configKey/' + configKey).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 阿里支付创建二维码
    aliPayPreCreate (totalAmount) {
        return new Promise((resolve, reject) => {
            axios.get('/pay/alipay/preCreate?totalAmount=' + totalAmount).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 阿里支付创建二维码
    alipayPagePay (totalAmount) {
        return new Promise((resolve, reject) => {
            axios.get('/pay/alipay/pagePay?totalAmount=' + totalAmount).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },


    /* 消耗余额商户新接口 */

    // 获取vip等级
    getVipLevel () {
        return new Promise((resolve, reject) => {
            axios.get('/newH5/vipLevel').then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 修改套餐标签
    changeTag (data) {
        return new Promise((resolve, reject) => {
            axios.post('/newH5/changeTag', data).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 查询未支付的基站服务费
    weixinJzNotPay (sn) {
        return new Promise((resolve, reject) => {
            axios.get('/pay/weixinJzNotPay?sn=' + sn).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 获取字典数据
    getDictData (type) {
        return new Promise((resolve, reject) => {
            axios.get('/dict/data?type=' + type).then(response => {
                resolve(response)
            }).catch(err => {
                reject(err)
            })
        })
    },

}
